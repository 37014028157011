import React from 'react';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const linkText = 'Learn more about our Data Usability Rating';
const extention = 'PDF';
const filePath = '/MultiPlan-Data-Usability-Rating-2024-09-24.pdf';

export default function DataUsabilityRatingLink() {
  return (
    <Link href={filePath} target="_blank" rel="noreferrer" sx={(theme) => ({ color: theme.palette.secondary.link })}>
      {linkText}
      <span style={{ textDecoration: 'none', display: 'inline-block', whiteSpace: 'pre' }}>{` (${extention})`}</span>
      <OpenInNewIcon fontSize="small" style={{ translate: '3px 3px' }} />
    </Link>
  );
}
