/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MTableToolbar } from '@material-table/core';
import { useSelector } from 'react-redux';
import {
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Tooltip, Typography, Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import ShareIcon from '@mui/icons-material/Share';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MAIN_HOST } from 'common/AppConstant';
import { decreaseFetchingQueue } from 'app/state/reducer';
import * as Constants from 'common/Constants';
import { confirmDialog } from 'common/components/ConfirmationDialog';
import { getAllNetworks } from 'features/common/state/service';
import RoutePath from 'app/router/RoutePath';
import {
  convertTimestamp,
  CONVERT_OBJECT_ARRAY_COMMA_STRING,
} from 'common/util/commonUtil';
import CustomInfoIcon from 'common/components/CustomInfoIcon';
import store from 'app/store';
import { usePermissions } from 'features/common/hooks/usePermissions';
import { PERMISSION } from 'common/Constants';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { getMenuCounts } from 'features/count/state/service';
import { resetEvaluation, setPrevVisitState } from './state/reducer';
import { deleteEvaluationById, togglePrivateStudy } from './state/service';

export default function Evaluation() {
  const navigate = useNavigate();
  const tableRef = useRef();

  const networkMap = useSelector((state) => state.provider.networkMap);
  const shouldRefresh = useSelector((state) => state.evaluation.shouldRefresh);
  const userId = useSelector((state) => state.auth.userId);
  const prevVisitState = useSelector((state) => state.evaluation.prevVisitState);
  const { hasPermission } = usePermissions();

  const [prevStateProcessed, setPrevStateProcessed] = useState(false);

  const navigateToDetail = (id, evalType) => {
    let link = RoutePath.app.evaluation.subpage.detail.path;

    if (id && id > 0) {
      link += `?id=${id}`;
    } else {
      store.dispatch(resetEvaluation());
    }
    navigate(link, { state: { evalType } });
  };

  const updateEvaluationCount = () => {
    const fetchCriteria = {
      fetchEvaluationCount: true,
      fetchSearchCount: false,
      fetchWeightsCount: false,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  };

  const handleDelete = (id) => {
    const confimrationMessage = 'Are you sure you want to delete this study?';
    confirmDialog(
      () => store.dispatch(deleteEvaluationById(id)),
      confimrationMessage,
    );
  };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const renderStatusIndicator = (status, color) => (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '14px',
        paddingY: '2px',
        maxWidth: '110px',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body1">
        {status}
      </Typography>
    </Box>
  );

  const statusRender = (status) => {
    if (status === Constants.EVALUATION_STATUS.COMPLETED) {
      return <Tooltip title={status}><CheckCircleOutlineIcon color="success" /></Tooltip>;
    } if (status === Constants.EVALUATION_STATUS.IN_PROGRESS) {
      return renderStatusIndicator('Processing', Constants.COLOR_PROCESSING);
    }
    return <Typography variant="body1">{status}</Typography>;
  };

  const renderProvider = (rd) => {
    if (rd.evalType === 'TAXID' && rd.npis !== null) {
      return rd.npis.length;
    }
    return '';
  };

  const renderName = (rowData) => {
    if (rowData.status === Constants.EVALUATION_STATUS.IN_PROGRESS) {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography>
            {rowData.name}
          </Typography>
          <CustomInfoIcon title="Study will be available when processing is complete" />
        </Stack>
      );
    }
    return (
      <Link
        component="button"
        underline="none"
        style={{
          fontSize: '1rem',
          overflowWrap: 'anywhere',
          textAlign: 'left',
          whiteSpace: 'normal',
        }}
        onClick={() => {
          navigateToDetail(rowData.id, rowData.evalType);
        }}
      >
        {rowData.name}
      </Link>
    );
  };

  const handleToggleShareStudy = (rd) => {
    if (rd.privateEvaluation === true) {
      const message = 'Are you sure you want to share this study?';
      confirmDialog(() => store.dispatch(togglePrivateStudy(rd.id)), message);
    } else {
      const message = 'Are you sure you want to make this study private?';
      confirmDialog(() => store.dispatch(togglePrivateStudy(rd.id)), message);
    }
  };

  // save table state before the component unmount
  useLayoutEffect(() => () => {
    const queryState = tableRef?.current?.state.query;
    let tableQuery = null;
    if (queryState) {
      const orderByArray = queryState.orderByCollection;
      tableQuery = {
        page: queryState.page,
        pageSize: queryState.pageSize,
        searchTerm: queryState.search,
        orderByCollection: orderByArray?.length === 1 ? [{ ...orderByArray[0] }] : null,
      };
    }
    store.dispatch(setPrevVisitState(tableQuery));
  }, []);

  useEffect(() => {
    if (shouldRefresh > 0) {
      refresh();
      updateEvaluationCount();
    }
  }, [shouldRefresh]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '20%',
      render: (rowData) => renderName(rowData),
    },
    {
      title: 'Study Type',
      field: 'evalType',
      width: '8%',
      render: (rd) => Constants.EVALTYPE_MAP[rd.evalType],
    },
    {
      title: 'Main Network',
      field: 'networkCode',
      width: '12%',
      render: (rd) =>
        networkMap[rd.networkCode] || rd.networkCode,
    },
    {
      title: 'Compared Network(s)',
      field: 'compareNetworks',
      width: '20%',
      sorting: false,
      render: (rd) => (CONVERT_OBJECT_ARRAY_COMMA_STRING(rd.compareNetworks, 'networkCode', networkMap)),
    },
    {
      title: 'Provider(s)',
      field: 'npis',
      width: '4%',
      render: (rd) => renderProvider(rd),
      sorting: false,
    },
    {
      title: 'Created By',
      field: 'studyCreatorName',
      width: '8%',
      render: (rd) => <Typography>{rd.studyCreatorName}</Typography>,
    },
    {
      title: 'Visibility',
      field: 'privateEvaluation',
      width: '4%',
      render: (rd) => (rd.privateEvaluation === true
        ? <Typography>Private</Typography> : <Typography>Shared</Typography>),
    },
    {
      title: 'Created',
      field: 'createdAt',
      width: '8%',
      render: (rowData) => convertTimestamp(rowData.createdAt),
    },
    {
      title: 'Status',
      field: 'status',
      width: '8%',
      render: (rowData) => (statusRender(rowData.status)),
    },
    {
      title: 'Input File',
      field: 'inputFile',
      width: '20%',
      render: (rowData) => (
        <Typography>{rowData.inputFile}</Typography>
      ),
    },
  ];

  useEffect(() => {
    store.dispatch(getAllNetworks());
  }, []);

  /* eslint-disable no-param-reassign */
  const prevVisitSorted = Array.isArray(prevVisitState?.orderByCollection);
  if (prevVisitSorted) {
    const orderByField = columns[prevVisitState.orderByCollection[0].orderBy]?.field;
    if (orderByField) {
      columns.forEach((column) => {
        if (column.field === orderByField) {
          column.defaultSort = prevVisitState.orderByCollection[0].orderDirection;
        }
      });
    }
  }

  return (
    <CustomizedMaterialTable
      title="Intelligence"
      options={{
        actionsColumnIndex: -1,
        search: true,
        pageSize: prevVisitState ? prevVisitState.pageSize : 10,
        searchText: prevVisitState ? prevVisitState.searchTerm : '',
        debounceInterval: 400,
        actionsCellStyle: { minWidth: '100px' },
        padding: 'dense',
        rowStyle: {
          wordBreak: 'break-word',
        },
      }}
      columns={columns}
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve) => {
          if (prevStateProcessed === false && prevVisitState !== null) {
            tableRef.current.onQueryChange(prevVisitState);
            setPrevStateProcessed(true);
          } else {
            axios
              .post(`${MAIN_HOST}/evaluation/search`, {
                page: query.page,
                size: query.pageSize,
                searchTerm: query.search,
                sortProperty: Array.isArray(query.orderByCollection)
                  && query.orderByCollection.length >= 1
                  ? columns[query.orderByCollection[0].orderBy].field
                  : '',
                direction: Array.isArray(query.orderByCollection)
                  && query.orderByCollection.length >= 1
                  && query.orderByCollection[0].orderDirection
                  ? query.orderByCollection[0].orderDirection.toUpperCase()
                  : Constants.DIRECTION_DESC,
              })
              .then((m) => {
                const { data } = m;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements,
                });
              })
              .catch(() => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
                store.dispatch(decreaseFetchingQueue());
              });
          }
        })
      }
      actions={
        [
          (rowData) => ({
            icon: () => <DeleteForeverIcon />,
            tooltip: 'Delete',
            hidden: !hasPermission(PERMISSION.CREATE_INTELLIGENCE),
            disabled: rowData.createdBy !== userId,
            onClick: () => handleDelete(rowData.id),
          }),
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true,
          },
          (rowData) => ({
            icon: () => <LockIcon />,
            tooltip: 'Hide Study',
            hidden: rowData.privateEvaluation === true,
            disabled: rowData.createdBy !== userId,
            onClick: () => handleToggleShareStudy(rowData),
          }),
          (rowData) => ({
            icon: () => <ShareIcon />,
            tooltip: 'Share Study',
            hidden: rowData.createdBy !== userId || rowData.privateEvaluation === false,
            onClick: () => handleToggleShareStudy(rowData),
          }),
        ]}
      components={{
        Toolbar: (props) => (
          <Box display="flex" flexDirection="row" alignItems="center" flexWrap="nowrap">
            <Box flex={1}>
              <MTableToolbar {...props} />
            </Box>
          </Box>
        ),
        OverlayLoading: () => null,
      }}
    />
  );
}
