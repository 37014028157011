import FilterListIcon from '@mui/icons-material/FilterList';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import React from 'react';
import { useSelector } from 'react-redux';

export default function EvaluationNpiLinkageDetail() {
  const npiLinkages = useSelector((state) => state.evaluation.npiLinkages);

  return (
    <CustomizedMaterialTable
      title="NPI Linkage Detail"
      options={{
        pageSizeOptions: 10,
        pageSize: 10,
        debounceInterval: 400,
        actionsCellStyle: { minWidth: '100px' },
        headerStyle: { position: 'sticky', top: 0 },
        maxBodyHeight: '550px',
      }}
      icons={{
        Search: () => <FilterListIcon />,
      }}
      localization={{
        toolbar: {
          searchPlaceholder: 'Filter',
        },
      }}
      columns={[
        {
          title: 'TIN',
          field: 'sourceNpi',
        },
        {
          title: 'NPI',
          field: 'providerNpi',
        },
        {
          title: 'Name',
          field: 'providerName',
        },
        {
          title: 'MSA',
          field: 'providerMsa',
        },
        {
          title: 'State',
          field: 'providerState',
        },
        {
          title: 'Zip',
          field: 'providerZip',
        },
        {
          title: 'Taxonomy',
          field: 'providerTaxonomy',
        },
        {
          title: 'NPI Type',
          field: 'npiType',
        },
      ]}
      data={npiLinkages}
    />
  );
}
